import NAV_DATA, {
  PrimaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';

const {
  PRIMARY: {
    REGISTRY,
    WEBSITE,
    PAPER,
    GUEST_LIST,
    WEDDING_BOUTIQUE,
    VENDORS,
    ACCOUNT,
    ALBUMS,
    ADVICE,
  },
} = NAV_DATA;

const titles = {
  [REGISTRY]: 'Registry',
  [WEBSITE]: 'Website',
  [PAPER]: 'Invites + Paper',
  [ALBUMS]: 'Albums',
  [GUEST_LIST]: 'Guest List',
  [WEDDING_BOUTIQUE]: 'Boutique',
  [VENDORS]: 'Vendors',
  [ADVICE]: 'Advice',
  [ACCOUNT]: 'Account',
};

const mapNavConstantsToTitle = (id: PrimaryNavIdentifier): string => titles[id] || '';

export default mapNavConstantsToTitle;
