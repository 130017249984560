import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './navLinkColumn.less';

const NavLinkColumn = ({ className, links, component: Component, borderLeft }) => (
  <Component
    className={cx('nav-link-column', { 'nav-link-column__borderLeft': borderLeft }, className)}
  >
    {links.map((link, i) => (
      <li className="nav-link-column__link" key={i}>
        {link}
      </li>
    ))}
  </Component>
);

NavLinkColumn.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.node),
  borderLeft: PropTypes.bool,
  component: PropTypes.string,
};

NavLinkColumn.defaultProps = {
  component: 'ul',
  links: [],
  borderLeft: false,
};

export default NavLinkColumn;
