import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useCopyToClipboard } from '@zola/zola-ui/src/hooks';
import { ExternalLinkIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ExternalLink';
import {
  SecondaryNavIdentifier,
  TertiaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';

import NavDropdown from 'components/navV2/NavDropdown';
import NavLink from 'components/navV2/NavLink';
import NavLinkColumn from 'components/navV2/NavLinkColumn';
import NavPrimaryRegistryDropdown from 'components/navV2/NavPrimaryRegistryDropdown';
import NavSecondaryRegistryDropdown from 'components/navV2/NavSecondaryRegistryDropdown';

import { getUserContext } from 'selectors/user';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import hasChildren from 'util/hasChildren';
import rmvSpaceAndSpecial from 'util/rmvSpaceAndSpecial';
import { sanitizeAndTrackNavigationClicked } from 'util/trackNavigationClickedHelpersV2';
import experimentFlags, { CLP_LP_LINKS_TO_SRP_V2 } from 'util/experimentFlags';
import { isDesktopV2 } from '@zola-helpers/client/dist/es';

const MARKETPLACE_NAV_IDS = [
  TertiaryNavIdentifier.VENUES,
  TertiaryNavIdentifier.PHOTOGRAPHERS,
  TertiaryNavIdentifier.VIDEOGRAPHERS,
  TertiaryNavIdentifier.CATERING,
  TertiaryNavIdentifier.FLORISTS,
  TertiaryNavIdentifier.HAIR_MAKEUP,
  TertiaryNavIdentifier.PLANNERS,
  TertiaryNavIdentifier.BANDS_DJS,
  TertiaryNavIdentifier.CAKES_DESSERTS,
  TertiaryNavIdentifier.BAR_SERVICES,
  SecondaryNavIdentifier.WEDDING_VENUES,
];

const shouldOpenNewTab = (isGuest, node = {}) => {
  if (isGuest) return false;
  return (
    node.title === 'Hotel blocks' ||
    node.identifier === 'preview' ||
    node.identifier === 'preview-registry-dropdown'
  );
};

const getLinkIcon = (isGuest, node = {}) => {
  if (shouldOpenNewTab(isGuest, node))
    return (
      <ExternalLinkIcon title="Link" height={20} width={20} className="nav-dropdown__icon--link" />
    );
  return null;
};

const getMenuPosition = (isPrimary, node = {}) => {
  if (node.id === 'kitchen' || node.id === 'dining') return 'centerRight';
  if (node.dockRight) return 'right';
  if (node.dockLeft) return 'left';
  if (node.centerDropdown || isPrimary) return 'center';
  return 'left';
};

/**
 * This is used to draw nav menu with drop downs.  It is used to draw the primary nav on
 * the home store, and the secondary nav elsewhere.  It does not draw the priamry navigation
 * menu on the web (non home store)
 *
 * @param {*} param0
 * @returns
 */
const NavMapper = ({
  navData,
  type,
  className,
  activeLinkId,
  activeSecondaryNavData,
  disableTracking,
}) => {
  const dispatch = useDispatch();
  // for post auth nav 3 we want to label nav items as tertiary but maintain the secondary styles
  const typeForStyling = type === 'Tertiary' ? 'Secondary' : type;
  const classNamePrefix = `nav${typeForStyling}`;
  const isPrimary = type === 'Primary';
  const isTertiary = type === 'Tertiary';

  const [, handleCopy] = useCopyToClipboard();

  const userContext = useSelector((state) => getUserContext(state));
  const isGuest = userContext ? Boolean(userContext.is_guest) : true;

  if (!navData.length) return null;

  // Renders the primary and secondary nav sub-menus;
  const renderChildren = (node = {}, grandparent) => {
    if (!hasChildren(node)) return null;

    // Registry drop menu is a special bb
    if (node.id === 'registry') return <NavPrimaryRegistryDropdown node={node} />;

    const onClick = (child) => {
      if (child.identifier === 'copy-registry-link') {
        const registryLink =
          typeof window !== 'undefined' ? `${window?.location?.origin}${child.path}` : undefined;
        if (registryLink) {
          handleCopy(registryLink);
          dispatch(toastsActions.positive({ headline: 'Link copied to clipboard' }));
        }
      }

      if (
        typeof window !== 'undefined' &&
        MARKETPLACE_NAV_IDS.includes(child.id) &&
        isDesktopV2()
      ) {
        experimentFlags.isUnderTest(CLP_LP_LINKS_TO_SRP_V2, userContext);
      }
      sanitizeAndTrackNavigationClicked({
        firstNode: activeSecondaryNavData,
        secondNode: grandparent,
        thirdNode: node,
        fourthNode: child,
        navigationType: 'CATEGORICAL',
        disableTracking,
      });
    };

    return (
      <NavLinkColumn
        links={node.children.map((child, i) => {
          return (
            <NavLink
              key={i}
              href={child.identifier === 'copy-registry-link' ? undefined : child.path}
              variant={`${typeForStyling}NavSubLink`}
              newTag={child.newTag}
              icon={child.icon}
              onClick={() => onClick(child)}
              target={shouldOpenNewTab(isGuest, child) ? '_blank' : '_self'}
            >
              {child.title}
              {getLinkIcon(isGuest, child)}
            </NavLink>
          );
        })}
      />
    );
  };

  // Sub-menus with multple columns
  const renderSubHeaderWithChildren = (firstNode = {}) => {
    if (!hasChildren(firstNode)) return null;

    const onClick = (link) => {
      sanitizeAndTrackNavigationClicked({
        firstNode: activeSecondaryNavData,
        secondNode: firstNode,
        thirdNode: link,
        navigationType: 'CATEGORICAL',
        disableTracking,
      });
    };

    return firstNode.children.map((secondNode, i) => (
      <div className={`${classNamePrefix}__dropdown-section`} key={i}>
        {secondNode.path ? (
          <NavLink
            href={secondNode.path}
            newTag={secondNode.newTag}
            variant={`${typeForStyling}NavSubHeader`}
            onClick={() => onClick(secondNode)}
          >
            {secondNode.title}
          </NavLink>
        ) : null}
        {renderChildren(secondNode, firstNode)}
      </div>
    ));
  };

  const renderDropdown = (node = {}) => {
    const nodeHasGrandchildren = node.children.some((child) => hasChildren(child));
    const classes = cx(
      `${classNamePrefix}__dropdown`,
      [`${classNamePrefix}__dropdown--${rmvSpaceAndSpecial(node.title)}`],
      { [`${classNamePrefix}__dropdown--hasGrandchildren`]: nodeHasGrandchildren }
    );

    const renderWhichNode = () => {
      // Registry drop menu is a special bb
      if (node.id === 'your-registry') return <NavSecondaryRegistryDropdown node={node} />;
      if (nodeHasGrandchildren) return renderSubHeaderWithChildren(node); // Multiple columns
      return renderChildren(node);
    };

    return (
      <NavDropdown
        className={classes}
        label={node.title}
        link={{
          href: node.path,
          target: shouldOpenNewTab(isGuest, node) ? '_blank' : '_self',
        }}
        eventType={node.id === 'about-registry' ? 'hoverButton' : 'hover'}
        variant={typeForStyling.toLowerCase()}
        showCaret={node.showCaret || isPrimary || (!isGuest && isTertiary)}
        menuPositionX={getMenuPosition(isPrimary, node)}
        isActive={activeLinkId === node.id}
        onClick={() => {
          sanitizeAndTrackNavigationClicked({
            firstNode: activeSecondaryNavData,
            secondNode: node,
            navigationType: 'CATEGORICAL',
            disableTracking,
          });
        }}
        icon={node.icon}
        iconNode={getLinkIcon(isGuest, node)}
      >
        {renderWhichNode()}
      </NavDropdown>
    );
  };

  const renderLink = (node = {}) => (
    <NavLink
      href={node.path}
      variant={`${typeForStyling}NavLink`}
      newTag={node.newTag}
      icon={node.icon}
      isActive={activeLinkId === node.id}
      className={`nav${typeForStyling}__${node.id}`}
      onClick={() => {
        sanitizeAndTrackNavigationClicked({
          firstNode: activeSecondaryNavData,
          secondNode: node,
          navigationType: 'CATEGORICAL',
          disableTracking,
        });
      }}
      target={node.target || shouldOpenNewTab(isGuest, node) ? '_blank' : '_self'}
      rel={node.target === '_blank' ? 'noopener noreferrer' : undefined}
    >
      {node.title}
      {getLinkIcon(isGuest, node)}
    </NavLink>
  );

  if (!navData.length) return null;
  // Renders the Primary or Secondary Nav items.
  return (
    <div className={cx(`${classNamePrefix}__container`, className)}>
      <ul className={`${classNamePrefix}__links`}>
        {navData.map((node, i) => {
          const nodeHasChildren = hasChildren(node);
          const classes = cx(`${classNamePrefix}__link`, {
            [`${classNamePrefix}__link--hasChildren`]: nodeHasChildren,
          });

          return (
            <li key={i} className={classes}>
              {nodeHasChildren ? renderDropdown(node) : renderLink(node)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

NavMapper.propTypes = {
  type: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']).isRequired,
  navData: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  activeLinkId: PropTypes.string,
  activeSecondaryNavData: PropTypes.shape({}),
  disableTracking: PropTypes.bool,
};

NavMapper.defaultProps = {
  navData: [],
  activeLinkId: '',
  disableTracking: false,
};

export default NavMapper;
