import { connect } from 'react-redux';
import { getUserContext } from 'selectors/user';
// For some g*d* reason, this wouldn't import/export properly if named 'NavLink.jsx'
import NavLink from './NavItem';

const mapStateToProps = (state) => ({
  userContext: getUserContext(state),
});

export default connect(mapStateToProps)(NavLink);
