import { useEffect } from 'react';
import _includes from 'lodash/includes';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { getBoutiqueNavItems } from '@zola-helpers/client/dist/es/nav/boutiqueNavData';
import {
  getRegistryNavItems,
  getRegistryQuickLinks,
  getRegistrySecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/registryNavData';

import { UNIFIED_NAV_MOUNTED } from 'components/nav/event/NavEvents';
import SecondaryNavMarketplaceData from 'components/nav/SecondaryNav/SecondaryNavData/SecondaryNavMarketplaceData';
import SecondaryNavPlanningData from 'components/nav/SecondaryNav/SecondaryNavData/SecondaryNavPlanningData';
import experimentFlags, { HOLIDAY_SHOPS } from 'util/experimentFlags';

const {
  EVENTS: { INIT_NAV_DATA },
} = NAV_DATA;

const webZolaAccountPaths = [
  '/account/settings/info',
  '/account/settings/orders',
  '/account/settings/credits',
  '/account/settings/notifications',
  '/account/settings/password-security',
  '/account/settings/invitepartner',
  '/account/settings/shipping',
  '/account/settings/bank',
  '/account/settings/cash',
];

const legacyNavCollapseExclusions = ['/search/wedding-registry'];

// TODO: somehow test this beast, add fallbacks for API errors
const useNavV1Methods = ({ maybeFetchUser, fetchCategories, fetchWeddingShopCategories }) => {
  // UNSAFE methods to support v1
  useEffect(() => {
    const isOnWebZolaAccountPages = _includes(webZolaAccountPaths, window.location.pathname);
    const isInExpertAdvice = _includes(window.location.pathname, 'expert-advice');
    const isNavCollapseExclusion = _includes(legacyNavCollapseExclusions, window.location.pathname);
    // Non-react apps that use the nav can sometimes have race conditions to fetch the userContext
    // which causes the nav to incorrectly render the secondary without the correct databases
    // Since v1 support is limited, we'll just force a second user fetch if race condition applies
    const shouldForceUserFetch = true;

    window.zolaNav = window.zolaNav || {};
    window.zolaNav.setSecondaryNavType = (type) => {
      if (!type) return undefined;

      if (type === 'wedding-shop') {
        Promise.all([maybeFetchUser(shouldForceUserFetch), fetchWeddingShopCategories()]).then(
          ([user, weddingShopNavData]) => {
            const isGuest = user.is_guest;
            const categoryData = getBoutiqueNavItems(user, weddingShopNavData);
            window.dispatchEvent(
              new CustomEvent(INIT_NAV_DATA, {
                detail: {
                  userContext: user,
                  secondaryData: isGuest ? categoryData : [],
                  tertiaryData: isGuest ? [] : categoryData,
                },
              })
            );
          }
        );

        return undefined;
      }

      if (type === 'marketplace') {
        maybeFetchUser(shouldForceUserFetch).then((user) => {
          const secondaryData = new SecondaryNavMarketplaceData(user).getItems();
          window.dispatchEvent(
            new CustomEvent(INIT_NAV_DATA, { detail: { userContext: user, secondaryData } })
          );
        });

        return undefined;
      }

      if (type === 'planning') {
        maybeFetchUser(true).then((user) => {
          const secondaryData = new SecondaryNavPlanningData(user).getItems();
          window.dispatchEvent(
            new CustomEvent(INIT_NAV_DATA, { detail: { userContext: user, secondaryData } })
          );
        });

        return undefined;
      }

      Promise.all([maybeFetchUser(shouldForceUserFetch), fetchCategories()]).then(
        ([user, navData]) => {
          const showHolidayShopNav = experimentFlags.getFlag(HOLIDAY_SHOPS, user);
          const registryCategories = getRegistryNavItems(user, navData, showHolidayShopNav);

          if (!user.is_guest) {
            const secondaryData = getRegistrySecondaryNavItems(user);
            const registrySlug = user?.has_registry
              ? user?.user_role_account_weddings?.[0].slug
              : undefined;
            const quickLinks = getRegistryQuickLinks(registrySlug);
            window.dispatchEvent(
              new CustomEvent(INIT_NAV_DATA, {
                detail: {
                  userContext: user,
                  secondaryData,
                  tertiaryData: registryCategories,
                  quickLinks,
                },
              })
            );
          } else {
            window.dispatchEvent(
              new CustomEvent(INIT_NAV_DATA, {
                detail: { userContext: user, secondaryData: registryCategories },
              })
            );
          }
        }
      );

      return undefined;
    };

    window.dispatchEvent(new CustomEvent(UNIFIED_NAV_MOUNTED));

    if (window.zolaNav.secondaryNavType) {
      window.zolaNav.setSecondaryNavType(window.zolaNav.secondaryNavType);
    }

    if (isOnWebZolaAccountPages || isInExpertAdvice) {
      maybeFetchUser().then((user) => {
        window.dispatchEvent(
          new CustomEvent(INIT_NAV_DATA, {
            detail: { userContext: user, disablePrimaryNavCollapse: true },
          })
        );
      });
    }

    if (isNavCollapseExclusion) {
      window.dispatchEvent(
        new CustomEvent(INIT_NAV_DATA, { detail: { disablePrimaryNavCollapse: true } })
      );
    }
  }, [maybeFetchUser, fetchCategories, fetchWeddingShopCategories]);
  return {};
};

export default useNavV1Methods;
