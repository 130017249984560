import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { WEBSITE },
} = NAV_DATA;

const getWebsite = (): PrimaryNavMenu => {
  return {
    title: 'Website',
    id: WEBSITE,
    path: '/wedding-planning/website',
    parent: 'Website', // a bit weird
  };
};

export default getWebsite;
