import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import type { PrimaryNavMenu } from './types';

const {
  PRIMARY: { ADVICE },
} = NAV_DATA;

const getAdvice = (): PrimaryNavMenu => {
  return {
    title: 'Advice',
    id: ADVICE,
    parent: 'Advice',
    path: '/expert-advice',
  };
};

export default getAdvice;
