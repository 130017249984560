import { connect } from 'react-redux';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { showModal } from 'actions/ModalActions';

import type { AppDispatch } from '../../../reducers';
import NavLoginOrSignUp from './NavLoginOrSignUp';

const {
  EVENTS: { MODAL_TYPE_AUTH },
} = NAV_DATA;

export type NavLoginOrSignUpDispatchProps = {
  onShowLogin: () => void;
};

export const mapDispatchToProps = (dispatch: AppDispatch): NavLoginOrSignUpDispatchProps => ({
  onShowLogin: () =>
    dispatch(
      showModal(
        MODAL_TYPE_AUTH,
        { authState: 'login' },
        { size: 'sm', ariaLabel: 'Log In', v2Styles: true }
      )
    ),
});

export default connect(null, mapDispatchToProps)(NavLoginOrSignUp);
