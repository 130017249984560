import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { REGISTRY },
} = NAV_DATA;

const getRegistry = (): PrimaryNavMenu => {
  return {
    title: 'Registry',
    id: REGISTRY,
    path: '/wedding-registry',
    parent: 'Registry',
  };
};

export default getRegistry;
