// utils
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

// types
import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { GUEST_LIST },
} = NAV_DATA;

const getGuestList = (): PrimaryNavMenu => {
  return {
    title: 'Guest List',
    id: GUEST_LIST,
    path: '/wedding-planning/guests',
    parent: 'GuestList',
    children: [],
  };
};

export default getGuestList;
