import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';

type MultiAnnouncementBannerWrapperProps = {
  isCollapsed?: boolean;
  leftAlignBanners?: boolean;
  backgroundColor?: string;
};
export const ArrowButton = styled(ButtonV3)`
  border: none;
  border-color: transparent !important;
  padding: ${SPACING.S4};
  svg {
    color: ${COLORS.WHITE_100};
  }
  opacity: 0;
`;

export const MultiAnnouncementBannerWrapper = styled.div<MultiAnnouncementBannerWrapperProps>`
  display: flex;
  justify-content: ${({ leftAlignBanners }) => (leftAlignBanners ? 'space-between' : 'center')};
  overflow: hidden;
  height: ${({ isCollapsed }) => (isCollapsed ? '0' : '56px')};
  background-color: ${({ backgroundColor }) => `${backgroundColor || COLORS.FOREST_100}`};

  transition: all 0.3s linear;
  &:hover {
    ${ArrowButton} {
      opacity: 1;
    }
  }
`;

export const PreauthLinksWrapper = styled.div`
  color: ${COLORS.WHITE_100};

  display: flex;
  gap: ${SPACING.S16};
  font-size: ${FONT.FONT_SIZE_EXTRA_SMALL};
  align-items: center;
  margin-right: ${SPACING.LG};
  flex-shrink: 0;
`;

export const StyledText = styled.p`
  font-size: ${FONT.FONT_SIZE_EXTRA_SMALL};
  color: ${COLORS.WHITE_100};
  margin: 0;
`;

export const StyledLink = styled(LinkV2)`
  font-size: ${FONT.FONT_SIZE_EXTRA_SMALL};
  color: ${COLORS.WHITE_100};
  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.WHITE_100};
  }
`;

export const BannerWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  margin: ${({ fullWidth }) => (fullWidth ? `0 ${SPACING.LG}` : `0 50px 0 ${SPACING.LG}`)};
  align-items: center;
  gap: ${SPACING.S16};
  width: ${({ fullWidth }) => (fullWidth ? `100%` : `auto`)};
`;
