import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { PAPER },
} = NAV_DATA;

const getInvites = (): PrimaryNavMenu => {
  return {
    title: 'Invites + Paper',
    id: PAPER,
    path: '/wedding-planning/paper',
    parent: 'Invites + Paper', // a bit weird
  };
};

export default getInvites;
