export const getZolaHref = (zolaEnv?: string): string => {
  switch (zolaEnv) {
    case 'development':
      return 'http://localhost';
    case 'staging':
      return 'https://qa.zola.com';
    case 'production':
    default:
      return 'https://www.zola.com';
  }
};
