import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { ACCOUNT },
} = NAV_DATA;

const getAccount = ({ userContext = {} }: { userContext?: UserContext }): PrimaryNavMenu => {
  const hasCompletedOnboarding = Boolean(userContext.has_completed_onboarding);
  const hasWeddingAccount = Boolean(userContext.has_wedding_account);
  const hasRegistry = Boolean(userContext.has_registry);

  const children = [
    {
      title: 'Your account',
      path: '/account/settings/info',
    },
    ...insertInArrayIf(hasCompletedOnboarding, {
      title: 'Your wedding details',
      path: '/account/settings/wedding',
    }),
    {
      title: "Orders you've placed",
      path: '/account/settings/orders',
    },
    {
      title: 'Ratings and reviews',
      path: '/account/settings/reviews',
    },
    {
      title: 'Zola store credit',
      path: '/account/settings/credits',
    },
    ...insertInArrayIf(hasRegistry || hasWeddingAccount, {
      title: 'Privacy settings',
      path: '/account/settings/privacy',
    }),
    {
      title: 'Invite friends',
      path: '/wedding-registry/invite',
    },
  ];

  return {
    title: 'Account',
    id: ACCOUNT,
    path: '/account/settings/info',
    parent: 'Account',
    children,
  };
};

export default getAccount;
