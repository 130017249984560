import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import type { WTopNavMessageView } from '@zola/svc-web-api-ts-client';

// Utils
import { getBabyHref } from 'util/getBabyHref';
import { getHomestoreHref } from 'util/getHomestoreHref';
import { getZolaHref } from 'util/getZolaHref';
import featureFlags from 'util/featureFlags';
import { AppDispatch } from 'reducers/index';

// Components
import { ChevronRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronRight';
import { ChevronLeftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronLeft';
import { TopNavMessageContext, getTopNavMessages } from 'client/v1/topNavMessages';
import useScrollPosition from '../../../hooks/useScrollPosition';
import { Banner } from './Banner/Banner';

// Styles
import {
  ArrowButton,
  BannerWrapper,
  MultiAnnouncementBannerWrapper,
  PreauthLinksWrapper,
  StyledLink,
  StyledText,
} from './MultiAnnouncementBanner.styles';

export type MultiAnnouncementBannerProps = {
  isPostAuth: boolean;
  showPreauthLinks?: boolean;
  showHomestoreLinks?: boolean;
  leftAlignBanners?: boolean;
  disableCollapse?: boolean;
};

const MultiAnnouncementBanner = ({
  isPostAuth,
  showPreauthLinks = false,
  showHomestoreLinks = false,
  leftAlignBanners = false,
  disableCollapse = false,
}: MultiAnnouncementBannerProps): JSX.Element => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [banners, setBanners] = useState<WTopNavMessageView[]>([]);

  const { background_color: backgroundColor = COLORS3.FOREST_100 } =
    banners.length !== 0 ? banners[currentBannerIndex] : {};

  const hasMultipleBanners = banners.length > 1;

  const dispatch = useDispatch<AppDispatch>();

  const { y: scrollY } = useScrollPosition();

  // The banner is collapsed when the page is scrolled down
  const isCollapsed = useMemo(() => {
    return !disableCollapse && scrollY > 0;
  }, [disableCollapse, scrollY]);

  useEffect(() => {
    const context = isPostAuth
      ? TopNavMessageContext.WEDDING_POST_AUTH
      : TopNavMessageContext.WEDDING_PRE_AUTH;
    getTopNavMessages(context)
      .then((response) => {
        if (response?.length > 0) {
          setBanners(response);
        }
      })
      .catch(() => undefined);
  }, [dispatch, isPostAuth]);

  const setNextBanner = useCallback(() => {
    // we've reached the end of the array, start from the beginning
    if (currentBannerIndex === banners.length - 1) {
      setCurrentBannerIndex(0);
    } else {
      setCurrentBannerIndex(currentBannerIndex + 1);
    }
  }, [currentBannerIndex, banners]);

  const setPreviousBanner = () => {
    // we've reached the beginning of the array, start from the end
    if (currentBannerIndex === 0) {
      setCurrentBannerIndex(banners.length - 1);
    } else {
      setCurrentBannerIndex(currentBannerIndex - 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNextBanner();
      setShowAnimation(false);
    }, 5000);

    const animationInterval = setInterval(() => {
      setShowAnimation(true);
    }, 4500);

    if (isHovering || isCollapsed || !hasMultipleBanners) {
      clearInterval(interval);
      clearInterval(animationInterval);
    }
    return () => {
      clearInterval(interval);
      clearInterval(animationInterval);
    };
  }, [setNextBanner, isHovering, isCollapsed, hasMultipleBanners]);

  const showBanner = (showBannerFunction: () => void) => {
    setShowAnimation(true);
    setTimeout(() => {
      showBannerFunction();
      setShowAnimation(false);
    }, 500);
  };

  const NextButton = () => {
    return (
      <ArrowButton
        variant="transparent"
        onClick={() => showBanner(setNextBanner)}
        title="Next announcement"
      >
        <ChevronRightIcon width={20} height={20} showTitle={false} />
      </ArrowButton>
    );
  };
  const PreviousButton = () => {
    return (
      <ArrowButton
        variant="transparent"
        onClick={() => showBanner(setPreviousBanner)}
        title="Previous announcement"
      >
        <ChevronLeftIcon width={20} height={20} showTitle={false} />
      </ArrowButton>
    );
  };

  return (
    <MultiAnnouncementBannerWrapper
      leftAlignBanners={leftAlignBanners}
      isCollapsed={isCollapsed}
      backgroundColor={backgroundColor}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <BannerWrapper fullWidth={!leftAlignBanners}>
        {!leftAlignBanners && hasMultipleBanners && <PreviousButton />}
        <Banner
          showAnimation={showAnimation}
          isLeftAligned={leftAlignBanners}
          banner={banners.length !== 0 ? banners[currentBannerIndex] : undefined}
        />
        {!leftAlignBanners && hasMultipleBanners && <NextButton />}
      </BannerWrapper>

      {showPreauthLinks && (
        <PreauthLinksWrapper>
          <StyledText>
            <StyledLink
              variant="tertiary"
              inline
              subtle
              noUnderline
              noTextTransform
              target="_blank"
              href={getBabyHref(featureFlags.getEnvironment())}
            >
              Zola Baby
            </StyledLink>
          </StyledText>
          <StyledText>
            <StyledLink
              variant="tertiary"
              inline
              subtle
              noUnderline
              noTextTransform
              target="_blank"
              href={getHomestoreHref(featureFlags.getEnvironment())}
            >
              Zola Home
            </StyledLink>
          </StyledText>
          <StyledText>
            <StyledLink
              variant="tertiary"
              inline
              subtle
              noUnderline
              noTextTransform
              target="_blank"
              href="/for-vendors"
            >
              Zola for vendors
            </StyledLink>
          </StyledText>
        </PreauthLinksWrapper>
      )}
      {showHomestoreLinks && (
        <PreauthLinksWrapper>
          <StyledText>
            <StyledLink
              variant="tertiary"
              inline
              subtle
              noUnderline
              noTextTransform
              target="_blank"
              href={getZolaHref(featureFlags.getEnvironment())}
            >
              Zola Weddings
            </StyledLink>
          </StyledText>
          <StyledText>
            <StyledLink
              variant="tertiary"
              inline
              subtle
              noUnderline
              noTextTransform
              target="_blank"
              href={getBabyHref(featureFlags.getEnvironment())}
            >
              Zola Baby
            </StyledLink>
          </StyledText>
        </PreauthLinksWrapper>
      )}
    </MultiAnnouncementBannerWrapper>
  );
};

export default MultiAnnouncementBanner;
