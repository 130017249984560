import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { WTopNavMessageView, WTopNavMessageDiscountView } from '@zola/svc-web-api-ts-client';

export enum TopNavMessageContext {
  WEDDING_PRE_AUTH = 'WEDDING_PRE_AUTH',
  WEDDING_POST_AUTH = 'WEDDING_POST_AUTH',
}

export function getTopNavMessages(context: TopNavMessageContext): Promise<WTopNavMessageView[]> {
  return ApiService.get(`/website-nav/web-api/v1/promoMessage/active/context/${context}`);
}

export function getTopNavDiscountTerms(id: string): Promise<WTopNavMessageDiscountView> {
  return ApiService.get(`/website-nav/web-api/v1/promoMessage/id/${id}/discount`);
}
