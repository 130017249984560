import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { WEDDING_BOUTIQUE },
} = NAV_DATA;

const getWeddingBoutique = (): PrimaryNavMenu => {
  return {
    title: 'Boutique',
    id: WEDDING_BOUTIQUE,
    path: '/shop/category/wedding-shop',
    parent: 'WeddingBoutique',
  };
};

export default getWeddingBoutique;
