import styled from '@emotion/styled';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import TagV2 from '@zola/zola-ui/src/components/Tag/TagV2';
import { FONT, SPACING, COLORS3 } from '@zola/zola-ui/src/styles/emotion';

export const BannerContainer = styled.a<{
  isLeftAligned: boolean;
  showAnimation: boolean;
}>`
  width: 100%;
  text-align: ${({ isLeftAligned }) => (isLeftAligned ? 'left' : 'center')};
  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.5s ease-in;
  opacity: ${({ showAnimation }) => (showAnimation ? 0 : 1)};

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledTag = styled(TagV2)`
  margin-right: ${SPACING.S16};
  height: 20px;
  align-self: center;
`;

export const BannerText = styled.p<{ color?: string }>`
  margin: 0;
  color: ${({ color }) => `${color || COLORS3.WHITE_100}`} !important;
  font-size: ${FONT.FONT_SIZE_SMALLER};
`;

export const CTAText = styled.p<{ textcolor?: string }>`
  margin: 0 0 0 ${SPACING.S24};
  color: ${({ textcolor }) => `${textcolor || COLORS3.WHITE_100} !important`};
  font-size: ${FONT.FONT_SIZE_SMALLER} !important;
  flex-shrink: 0;
  font-weight: ${FONT.FONT_WEIGHT_SEMI_BOLD};
  text-decoration: underline;
`;

export const TermsLink = styled(LinkV2)<{ textcolor?: string }>`
  font-size: ${FONT.FONT_SIZE_EXTRA_SMALL} !important;
  color: ${({ textcolor }) => `${textcolor || COLORS3.WHITE_100} !important`};
  margin-left: ${SPACING.S4};
`;
