import getRegistry from './getRegistry';
import getAlbums from './getAlbums';
import getInvites from './getInvites';
import getWebsite from './getWebsite';
import getGuestList from './getGuestList';
import getWeddingBoutique from './getWeddingBoutique';
import getVendors from './getVendors';
import getAdvice from './getAdvice';
import getAdvisor from './getAdvisor';
import { PrimaryNavMenu } from './types';

export const getPrimaryNavData = (): { children: PrimaryNavMenu[] } => {
  return {
    children: [
      getRegistry(),
      getInvites(),
      getWebsite(),
      getGuestList(),
      getWeddingBoutique(),
      getAlbums(),
      getVendors(),
      getAdvice(),
      getAdvisor(),

      // filter is a hack but it removes the null from getDashboard if user is not logged in
    ]
      .filter((node) => !!node)
      // position is 1-indexed here.,
      .map((node, i) => ({ ...node, position: i + 1 })),
  };
};
