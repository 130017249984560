import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { VENDORS },
} = NAV_DATA;

const getVendors = (): PrimaryNavMenu => {
  return {
    title: 'Vendors',
    id: VENDORS,
    path: '/wedding-vendors',
    parent: 'Vendors',
  };
};

export default getVendors;
