import { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';
import { PrimaryNavMenu } from './types';

const getAdvisor = (): PrimaryNavMenu => {
  return {
    title: '1:1 Advisors',
    id: PrimaryNavIdentifier.ADVISOR,
    path: '/advisor',
    parent: '1:1 Advisors',
    children: [],
  };
};

export default getAdvisor;
