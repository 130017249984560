import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

import { PrimaryNavMenu } from './types';

const {
  PRIMARY: { ALBUMS },
} = NAV_DATA;

const getAlbums = (): PrimaryNavMenu => {
  return {
    id: ALBUMS,
    title: 'Albums',
    path: '/wedding-albums/shop',
    parent: 'Albums',
  };
};

export default getAlbums;
